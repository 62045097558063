<template>
  <div>
    <Card>
      <template #header>Ekspertiz Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addExper = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Ekspertiz Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="updateValue(item)">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addExper" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>Yeni Ekspertiz Kayıt Ekleme</h4>
      </template>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="form-group">
            <label for="">Rapor Tarihi</label>
            <input type="date" class="form-control" v-model="report_date" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Talep No</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="request_number"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Firma Adı</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="company_name"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Yasal Durum</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="legal_status"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Acil Satış Değeri </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="urgent_sale_value"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Piyasa Değeri</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="market_value"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Sigortaya E. D. </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="e_d_to_insurance"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Bilgi Amaçlı D.</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="informational_d"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ort. Piyasa D.</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="cover_market_d"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ort. Yasal D. D.</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="avg_legal_d_d"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ort. Bilgi A. D.</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="cover_information"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Eksper Kanaati</label>
            <textarea
              name=""
              id=""
              rows="4"
              class="form-control"
              v-model="expert_opinion"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kullanıcı</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="user"
            ></textarea>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="resetValue = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="ekspertiz"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      date: "",
      company_name: "",
      report_date: "",
      request_number: "",
      legal_status: "",
      urgent_sale_value: "",
      market_value: "",
      e_d_to_insurance: "",
      informational_d: "",
      cover_market_d: "",
      avg_legal_d_d: "",
      cover_information: "",
      expert_opinion: "",
      user: "",
      addExper: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Rapor Tarihi", value: "report_date" },
        { text: "Talep No", value: "request_number" },
        { text: "Firma Adı", value: "company_name" },
        { text: "Yasal Durum D.", value: "legal_status" },
        { text: "Piyasa D.", value: "market_value" },
        { text: "Sigortaya E. D.", value: "e_d_to_insurance" },
        { text: "Bilgi Amaçlı D.", value: "informational_d" },
        { text: "Ort. Piyasa D.", value: "cover_information" },
        { text: "Ort. Yasal D. D.", value: "avg_legal_d_d" },
        { text: "Ort. Bilgi A. D.", value: "cover_market_d" },
        { text: "Acil Satış Değeri", value: "urgent_sale_value" },
        { text: "Kullanıcı", value: "user" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      report_date: "",
      request_number: "",
      legal_status: "",
      urgent_sale_value: "",
      market_value: "",
      e_d_to_insurance: "",
      informational_d: "",
      cover_market_d: "",
      avg_legal_d_d: "",
      cover_information: "",
      expert_opinion: "",
      user: "",
      updateModal :false,
      expertise_id : ""
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        report_date: moment(this.report_date).format("DD/MM/YYYY"),
        request_number: this.request_number,
        legal_status: this.legal_status,
        urgent_sale_value: this.urgent_sale_value,
        market_value: this.market_value,
        e_d_to_insurance: this.e_d_to_insurance,
        informational_d: this.informational_d,
        cover_market_d: this.cover_market_d,
        avg_legal_d_d: this.avg_legal_d_d,
        cover_information: this.cover_information,
        expert_opinion: this.expert_opinion,
        user: this.user,
        user_token: this.myUser,
        property_id: this.$route.params.id,
        company_name: this.company_name,
      };
    if(!this.updateModal){
      this.$ApiService
        .post("property/expertise", data)
        .then((res) => {
          this.$generateNotification(this, "success", "Ekspertiz Eklenmiştir.");
          this.getList();
          this.addExper = false;
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Ekspertiz Eklenemedi.");
        });
    }else{
      data["expertise_id"] = this.expertise_id;
      const formData = new FormData();
      for(const key in data){
        formData.append(key, data[key])
      }
      this.$ApiService
      .put("property/expertise" , formData)
      .then((res) => {
        this.$generateNotification(
          this,"success", "Ekspertiz Başarıyla Güncellendi."
        )
        this.getList()
        this.addExper = false
      })
      .catch((err) => {
        this.$generateNotification(
          this,"error","Ekspertiz Güncellenemedi."
        )
      })
    }
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/expertise?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperationekspertiz");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
    updateValue(item) {
      this.report_date = moment(item.report_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      this.request_number = item.request_number;
      this.legal_status = item.legal_status;
      this.urgent_sale_value = item.urgent_sale_value;
      this.market_value = item.market_value;
      this.e_d_to_insurance = item.e_d_to_insurance;
      this.informational_d = item.informational_d;
      this.cover_market_d = item.cover_market_d;
      this.avg_legal_d_d = item.avg_legal_d_d;
      this.cover_information = item.cover_information;
      this.expert_opinion = item.expert_opinion;
      this.company_name = item.company_name;
      this.user = item.user;
      this.expertise_id = item._id.$oid;
      this.addExper = true;
      this.updateModal = true
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = "property/expertise";
      this.deleteIds = `?user_token=${this.myUser}&expertise_id=${item._id.$oid}`;
      this.$bvModal.show("modal-deleteOperationekspertiz");
    },
    resetValue() {
      this.request_number = ""
      this.legal_status = "";
      this.urgent_sale_value = "";
      this.market_value = "";
      this.e_d_to_insurance = "";
      this.informational_d = "";
      this.cover_market_d = "";
      this.avg_legal_d_d = "";
      this.cover_information = "";
      this.expert_opinion = "";
      this.company_name = "";
      this.user = "";
      this.expertise_id ="";
      this.addExper = false;
      this.updateModal = false;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
